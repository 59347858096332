import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { MenueContext } from '../../../providers/menue'
import { RestaurantContext } from '../../../providers/restaurant'
import { PreorderContext } from '../../../providers/reservation'

import { useScrollOnRender } from '../../../utils/hooks/scroll'
import useResizeWindows from '../../../utils/hooks/useResizeWindow'

import { API_HOST, API_URL } from '../../../App'
import { Modal } from 'antd'

import './style.scss'


const measure = (isDrink) => {
    return isDrink ? 'МЛ' : 'ГР'
}

const loadingPlaceholder = (<div>
    <p>
        Для Вашего дальнейшего удобства (и для экономии Интернет-траффика)<br/>
        мы предзагружаем (и периодически перезагружаем) всё меню, что может занять некоторое время.<br/>
    </p>
    <p>
        Вы можете дальше бродить по сайту - загрузка не прервётся.
    </p>
</div>)

function MenuItemDetails({
    id,
    imgSrc, title, grams,
    description, price,
    is_drink,
    preorderAdd,
}) {
    return (
        <div className="modal mobile-bar">
            <div className="img-wrapper">
                <img src={imgSrc} alt={'Профессионально сделанная фотография ' + title + ' в высоком разрешении'} />
            </div>
            <div className="text-wrapper">
                <div className="head">
                    <p className="tg-authentic tg-upper title">{title}</p>
                    <p className="grams">{grams}{measure(is_drink)}</p>
                </div>
                {
                    description || (
                    <p className="description">Описание не предоставлено</p>
                    )
                }
                <p className="price tg-authentic">{price}₽</p>
                {
                    preorderAdd && <button className="primary tg-upper"
                        onClick={preorderAdd}
                    >Добавить в предзаказ</button>
                }
            </div>
        </div>
    )
}

function MenuItem({
    id,
    imgSrc, title, grams, price,
    description, is_drink,
    preorder,
    openDetails,
}) {
    const {
        preorderState,
    } = useContext(PreorderContext)

    const quantity = preorderState.menue.find(i => i.id === id)?.quantity || 0

    return (
        <div className="item"
            onClickCapture={e => {
                if (!(e.target.closest('.menu-item-count-wrapper') || e.target.closest('.zero-items'))) {
                    openDetails({
                        id, imgSrc, title, grams, price,
                        description,
                        is_drink,
                        preorderAdd: preorder ? (e) => {preorder.menuAdd(e)} : undefined,
                    })
                } 
            }}
        >
            <img src={imgSrc} alt="menu item" />
            <p className="title">{title}</p>
            <p className="weight">{grams}{measure(is_drink)}</p>
            <div className="row">
                <p className="price">{price}₽</p>
                {preorder ? (
                    quantity > 0 ? (
                        <div className="menu-item-count-wrapper">
                            <span className="order-count">
                                <svg className="minus"
                                viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={preorder.menuDelete}>
                                <circle cx="33.4994" cy="33.7143" r="32.1071" fill="white" stroke="#41B64D" stroke-width="2.21429"/>
                                <path d="M27.2997 37.6594V34.8694H40.5854V37.6594H27.2997Z" fill="#41B64D"/>
                                </svg>
                                <span className="count">{quantity}</span>
                                <svg className="plus"
                                viewBox="0 0 68 67" fill="none" xmlns="http://www.w3.org/2000/svg"
                                onClick={preorder.menuAdd}>
                                <circle cx="33.9272" cy="33.7143" r="32.1071" fill="white" stroke="#41B64D" stroke-width="2.21429"/>
                                <path d="M32.024 44.3908V35.6665H23.2997V32.8765H32.024V24.1522H34.814V32.8765H43.5161V35.6665H34.814V44.3908H32.024Z" fill="#41B64D"/>
                                </svg>
                            </span>
                        </div>
                        )
                    : (<svg className="zero-items" onClick={preorder.menuAdd} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#41B64D"/>
                        <path d="M19.5431 30.1965V20.8922H10.1953V19.5878H19.5431V10.1965H20.8475V19.5878H30.1953V20.8922H20.8475V30.1965H19.5431Z" fill="white"/>
                    </svg>)
                ) : null
                }
            </div>
        </div>)
}

const transformItems = (i) => {
    return {
        ...i,
        imgSrc: API_HOST + i.image,
        grams: i.weight,
    }
}

export function downloadMenu(path) {
    window.open(API_HOST + '/media/' + path).focus()
}

function Expander({
    variant, state: stateText,
    onClick,
}) {
    const appearance = variant === "menu" ?
        "primary"
        : "secondary"

    return <button className={[appearance, "tg-upper", "expander"].join(' ')}
        onClick={onClick}
    >{
        variant === "menu" ?
        "Меню"
        : stateText
    }</button>
}
                
export function MenuElem({
    // можно кидать init prop сверху из состояния предзаказа
    preorderCalculator, preorderCb,
    view = "menu", onParentCatChange=undefined
}) {
    useScrollOnRender(true)
    const width = useResizeWindows()

    const {
        preorderState,
    } = useContext(PreorderContext)

    const [menuDetails, setMenuDetails] = useState({
        showing: false,
    })
    const [showingCats, setShowingCats] = useState(false)
    function openMenuDetails(item) {
        setMenuDetails({showing: true, ...item})
    }

    const rc = useContext(RestaurantContext)
        , {
            places, current
        } = rc
    const mc = useContext(MenueContext)
        , {
        menue, setMenue
    } = mc
        , [loadingMenu, setLoadingMenu] = useState(!menue)

    const getMenu = useCallback(
        (restId = places?.[current]?.id) => {
            setLoadingMenu(true)
            if (typeof restId !== "undefined") {
                return fetch(
                    API_URL + `/menu/${restId}`, //${restId}?page=${page}
                    // {cache: 'reload'}
                ).then(
                    response => response.json()
                ).then(
                    content => {
                        // const {
                        //     pages, current, total, items
                        // } = content
                        setMenue(content) //items
                        // setPaging({pages, current, total})
                    }
                ).finally(
                    () => setLoadingMenu(false)
                )
            } else {
                return Promise.reject()
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [places, current]
    )

    useEffect(() => {
        const restId = places?.[current]?.id
        if (!restId) {
            getMenu().catch(
                e => {
                    
                }
            ).finally(
                () => {
                    // console.log("set menue", menue)
                }
            )
        } else {
            getMenu(restId).catch(
                e => {
                    
                }
            ).finally(
                () => {
                    // console.log("set menue", menue)
                }
            )
        }
    }, [places, current, getMenu])

    const parentCats = useMemo(() => {
        const ids = new Set()
            , ret = []

        menue?.forEach(
            i => {
                const p = i.category?.parent
                if (p) {
                    if (typeof p.id === 'number' && !ids.has(p.id)) {
                        ids.add(p.id)
                        ret.push(p)
                    }
                }
            }
        )
        return ret.filter(
            c => {
                if (view === 'preorder') return !c.hide_in_preorder
                else if (view === 'kitchen') return c?.name !== 'Бар'
                else if (view === 'bar') return c?.name === 'Бар'
                else return true
            }
        )
    }, [menue, view])

    const [activeParentCat, setActiveParentCat] = useState(0)

    useEffect(() => {
        if (parentCats?.length > 0) {
            let idx
            if (view === 'bar') {
                idx = parentCats.findIndex(i => i.name === 'Бар')
                setActiveParentCat(idx)
            } else {
                idx = parentCats.findIndex(i => i.name === 'Кухня')
                setActiveParentCat(idx)
            }
            onParentCatChange?.(parentCats[idx].name)
        }
    }, [parentCats, view])

    const activeParentCatName = useMemo(() => parentCats?.[activeParentCat]?.name,
        [parentCats, activeParentCat]
    )
    const [subCatActive, setSubCatActive] = useState(-1)

    const subcats = useMemo(() => {
        const ids = new Set()
            , ca = []
        menue?.forEach(
            i => {
                const cName = i.category.name
                    , cId = i.category.id
                    , cpName = i.category.parent?.name
                    // , cpId = i.category.parent?.id
                    , needHideInPreorder = view === 'preorder' && i.category.hide_in_preorder
                if (
                    cpName
                    && cpName === activeParentCatName
                    && !ids.has(cId)
                    && !needHideInPreorder
                ) {
                    ids.add(cId)
                    ca.push(cName)
                }
            }
        )
        return ca
    }, [menue, activeParentCatName, view])

    const renderSubcategories = (
        target, active,
    ) => target.map(
        (subCat, idx) => {
            // full control
            const id = idx + 1
                , active_ = idx === active
            let idAttr = `kitchen-menu-category-${id}`
            
            return (
                <a className={active_ ? 'active' : ''} key={idAttr} id={idAttr}
                    onClick={e => {
                        e.preventDefault()
                        setSubCatActive(idx)
                        // view === "menu" && setBranch('kitchen')
                    }}
                    href={`#${idAttr}`}
                ><li>{subCat || '<пустая категория>'}</li></a>
            )
        }
    )
    const renderMobileSubcategories = () => {
        return <ul>
            {
                subcats.map(
                    (sc, idx) => (
                        <li key={activeParentCat + ' ' + idx}>
                            <label>
                                <input type="radio"
                                    checked={subCatActive === idx}
                                    onChange={e => {
                                        setSubCatActive(idx)
                                        setShowingCats(false)
                                    }}
                                />
                                {sc}
                            </label>
                        </li>
                    )
                )
            }
        </ul>
    }

    function renderCategories() {
        return (
            <aside className="categories">
                {
                    (view === 'menu' || view === 'kitchen' || view === 'preorder') ? parentCats.map(
                        (c, idx) => {
                            const elem = (
                                <>
                                {
                                    (c.name === 'Ланч' && !places?.[current]?.show_lunch_menu) ? null : <>
                                    {idx !== 0 && <hr/>}<ul>
                                        <a className={'parent-category' + (activeParentCatName === c.name ? " active" : "")} id="kitchen-menu" href="#kitchen-menu"
                                            onClick={e => {
                                                e.preventDefault()
                                                setActiveParentCat(prev => idx)
                                                onParentCatChange?.(parentCats[idx].name)
                                                setSubCatActive(-1)
                                            }}
                                        ><li>{c.name}</li></a>
                                        {
                                            activeParentCatName === c.name && renderSubcategories(subcats, subCatActive)
                                        }
                                    </ul>
                                    </>
                                }
                                </>
                            )
                            return elem
                        }
                    )
                    : <ul>{renderSubcategories(subcats, subCatActive)}</ul>
                }
            </aside>
        )
    }

    const [expandMenuBtn, setExpandMenuBtn] = useState({
        showing: width < 884 || (view === 'bar' || view === 'kitchen'),
        toShow: width < 448 ? 6 : (width < 884 || (view === 'bar' || view === 'kitchen')) ? 8 : 0,
        expand: true,
    })

    useEffect(() => {
        const l = e => {
            if (width <= 448) {
                setExpandMenuBtn({...expandMenuBtn, toShow: 6, showing: true})
            } else if (width <= 884 || (view === 'bar' || view === 'kitchen')) {
                setExpandMenuBtn({...expandMenuBtn, toShow: 8, showing: true})
            } else setExpandMenuBtn({
                showing: false,
                toShow: 8,
            })
        }
        window.addEventListener('resize', l)
        return () => {
            window.removeEventListener('resize', l)
        }
    }, [expandMenuBtn, view, width])

    const categoriesHead = useMemo(() => {
        const btn = <button className="primary tg-upper"
            style={{
                marginBlockEnd: 32,
            }}
            onClick={e => {
                setShowingCats(true)
            }}>Категории</button>
            , mobileTitle = (
                <h3 className="tg-authentic tg-upper mobile-subcat-active"
                style={{
                    marginBlockStart: 32,
                }}
                >{
                    subCatActive > -1 ?
                    subcats[subCatActive]
                    : view === 'bar' ? "Меню бара"
                    : activeParentCatName
            }</h3>
        )
        return (width < 884 && (
            <>
            {/* {view === "menu" && btn}
            {view === "menu" && <hr/>} */}
            {mobileTitle}
            {btn}
            </>
        )) || null
    }, [view, subCatActive, subcats, width, activeParentCatName])

    const renderedItems = useMemo(() => {
        const preorder = preorderCalculator && !preorderState.formed ? true : false
        const v = menue?.map(transformItems).filter(
            // временно, пока не избавимся от такого в скрипте импорта меню
            // нет ничего более постоянного, чем временное
            i => i.price < 9.223372036854776e+16
        ).filter(
            i => {
                if (subCatActive > -1) {
                    return (
                        i.category.parent
                        && i.category.parent.name === activeParentCatName
                        && i.category.name === subcats[subCatActive]
                    )
                } else return i.category.parent?.name === activeParentCatName
            }
        )

        return v?.map(
            item => {
                const {id} = item
                return <MenuItem key={id} openDetails={openMenuDetails} preorder={preorder && {
                    menuAdd: e => {
                        preorderCb(menue, id, "inc")
                    },
                    menuDelete: e => {
                        preorderCb(menue, id, "dec")
                    },
                }} {...item}/>
            }
        ) || []
    }, [
        subCatActive, preorderCalculator, preorderState,
        menue, subcats, activeParentCatName, preorderCb,
    ])

    const subcategoryRenderedItems_ = useMemo(() => {
        const preorder = preorderCalculator && !preorderState.formed ? true : false
            , ret = []
        subcats.forEach(
            sc => {
                ret.push(<p className='subcategory-title'>{sc}</p>)
                ret.push(<div className="menu-grid">{menue.map(transformItems).filter(
                    i => i.category.parent?.name === activeParentCatName && i.category.name === sc
                ).map(
                    i => {
                        const {id} = i
                        return <MenuItem key={id} openDetails={openMenuDetails} preorder={preorder && {
                            menuAdd: e => {
                                preorderCb(menue, id, "inc")
                            },
                            menuDelete: e => {
                                preorderCb(menue, id, "dec")
                            },
                        }} {...i}/>
                    }
                )}</div>)
            }
        )
        return <div>{ret}{preorder && preorderCalculator}</div>
    }, [menue, activeParentCatName, subcats, preorderCalculator, preorderState, preorderCb])
          
    return (
        <>
        <Modal
            open={menuDetails.showing}
            onCancel={(e) => {
                setMenuDetails({showing: false})
            }}
            style={{
                marginInline: width < 884 ? 0 : undefined,
                width: '100%',
                maxWidth: width < 884 ? '100%' : undefined,
            }}
            footer={null}
        >
            <div id="menu-item-details">
                <MenuItemDetails {...menuDetails} />
            </div>
        </Modal>
        <Modal
            open={showingCats}
            onCancel={e => {
                setShowingCats(false)
            }}
            width="100%"
            style={{
                marginInline: 0,
                maxWidth: "100%",
            }}
            // closeIcon={
            //     width < 448 ? <div
            //       className='modal-mobile-closer'
            //     ></div>
            //     : undefined
            // }
            footer={null}
        >
            <div id="mobile-cats">
                {
                    parentCats.map(
                        (c, idx) => {
                            return (
                                <>
                                    {
                                        (c.name === 'Ланч' && !places?.[current]?.show_lunch_menu) ? null : <>
                                        {idx !== 0 && <hr/>}<div className={'category-group' + (activeParentCatName === c.name ? " _active" : "")}>
                                            <p className="head"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setActiveParentCat(prev => idx)
                                                    onParentCatChange?.(parentCats[idx].name)
                                                    setSubCatActive(-1)
                                                    setShowingCats(false)
                                                }}
                                            >{c.name}</p>
                                            {
                                                renderMobileSubcategories()
                                            }
                                        </div>
                                        </>
                                    }
                                </>
                            )
                        }
                    )
                }
            </div>
        </Modal>
        <section className="block container menu">
            <div class="categories-wrapper-outer">
            {
                view === "bar" && (
                    <h4 className='tg-authentic tg-large tg-upper'
                        data-view={`view-${'bar'}`}
                    >Меню<br/>бара</h4>
                )
            }
            {
                view === "kitchen" && (
                    <h4 className='tg-upper'
                        data-view={`view-${'kitchen'}`}
                    >Меню<br/>кухни</h4>
                )
            }
                <div className="categories-wrapper">
                    {renderCategories()}
                </div>
            </div>
            {categoriesHead}
            {
                loadingMenu ? (loadingPlaceholder)
                : renderedItems ? 
                    renderedItems.length === 0 ?
                    <p>Для данной категории ничего не найдено</p>
                    : subCatActive === -1 ? subcategoryRenderedItems_ : (
                        <div className="menu-grid">
                            {
                                expandMenuBtn.showing && expandMenuBtn.expand ?
                                renderedItems.slice(0, expandMenuBtn.toShow)
                                : renderedItems
                            }
                            {
                                expandMenuBtn.showing && renderedItems.length > expandMenuBtn.toShow &&
                                <Expander
                                    onClick={e => {
                                        setExpandMenuBtn({
                                            ...expandMenuBtn,
                                            expand: !expandMenuBtn.expand,
                                        })
                                    }}
                                    state={expandMenuBtn.expand ? 'Развернуть' : 'Свернуть'}
                                />
                            }
                            {preorderCalculator}
                        </div>
                    )
                : <p>Some other issue: {JSON.stringify(menue)}</p>
            }
        </section>
        </>
    )
}
