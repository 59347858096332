import React, { useMemo, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { downloadMenu, MenuElem } from '../../components/shared/Menu/Menu'
import { RestaurantContext } from '../../providers/restaurant'
import useResizeWindows from '../../utils/hooks/useResizeWindow'
import LunchSpecialPicture from "../../assets/img/menu-lunch-special.jpg"
import LunchSpecialPictureMob from "../../assets/img/menu-lunch-special-mobile.jpg"
import BarSpecialPicture from "../../assets/img/menu-bar-special.jpg"
import MenuSpecialPicture from "../../assets/img/menu-kitchen-special.jpeg"

import './style.scss'


const Menu = ({
  preorderCalculator = null
}) => {
  const width = useResizeWindows()
      , navigate = useNavigate()
      , {
    places, current
  } = useContext(RestaurantContext)
      , [currentCat, setCurrentCat] = useState()
      , menuHero = useMemo(() => {
        switch (currentCat) {
          case "Ланч":
            return <section id="lunch-special">
              <img src={width > 678 ? LunchSpecialPicture : LunchSpecialPictureMob} alt='Ланч спецпредложение' />
              <div style={{
                fontFamily: "Mont",
              }}>
                  <p><span>Салат + Горячее</span><span class="price">235₽</span></p>
                  <p><span>Суп + Горячее</span><span class="price">230₽</span></p>
                  <p><span>Салат + Суп + Горячее</span><span class="price">295₽</span></p>
              </div>
            </section>
          case "Бар":
            return <section id="bar-special">
              <img src={BarSpecialPicture} alt='Бар' />
            </section>
          case "Обед":
            return <section id="kitchen-special">
              <img src={MenuSpecialPicture} alt="Обед спецпредложение" />
            </section>
          default:
            return <section className="block container hero-menu">
              <h1 className='tg-upper meta'><p>Меню</p></h1>
              <button
                className="calculator primary tg-upper"
                onClick={e => navigate("/order")}
              >Калькулятор предзаказа</button>
              <button className="download secondary tg-upper"
                onClick={e => downloadMenu(places?.[current]?.menue_file)}
              >Скачать меню</button>
            </section>
        }
      }, [places, current, width, currentCat])

  return (
    <>
    {menuHero}
    <MenuElem preorderCalculator={preorderCalculator}
      onParentCatChange={setCurrentCat}
    />
    </>
  )
}

export default Menu;
